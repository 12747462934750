<template>

  <div>

    <CRow>
      <CCol sm="12">
        <CCardHeader>

          <strong>Casa Funeraria</strong>
        </CCardHeader>

        <CCard>

          <CCardBody>
            <div>
              <CRow>

                <CCol sm="12">
                  <strong>Nome</strong>
                  <br>
                  <br>
                  <CInput
                      v-model="item.nome"
                  />
                </CCol>


                <CCol sm="12">
                  <strong>Descrizione</strong>
                  <br>
                  <br>
                  <CCard class="customCard">
                    <textarea v-model="item.descrizione" rows="20" cols="80"></textarea>
                  </CCard>
                </CCol>
              </CRow>

              <CRow>

                <CCol sm="12">
                  <br>
                  <div>
                    <UploadMultiplePhotoAndPreview
                        title="Casa Funeraria"
                        subtitle="Casa Funeraria"
                        @getPhoto="(data) => { item.foto = data }"
                        :img="item.foto"
                    />
                  </div>
                </CCol>
              </CRow>

              <CButton
                  v-on:click="
                  () =>
                  {
                      if(this.$route.params.id !== undefined) {
                         this.updateCasaFuneraria(this.item)
                      } else {
                         this.createCasaFuneraria(this.item)
                      }
                       this.$router.push({name: 'listaCaseFunerarie' });
                  }
                  "
                  type="submit"
                  size="lg"
                  color="primary"
              >
                <CIcon name="cil-save"/>
                Salva
              </CButton>

            </div>

          </CCardBody>

        </CCard>
      </CCol>
    </CRow>

    <CRow v-if="item.id !== undefined">
      <CCol sm="12">
        <CCardHeader>
          <strong>Stanze Funerarie</strong>
        </CCardHeader>
        <CCard class="customCard">

          <CCardBody>

            <div v-for="stanza in items" :key="stanza.id">
              <CCardHeader>

            <CButton
                    v-on:click="updateStanzaFuneraria(stanza)"
                    type="submit"
                    size="md"
                    color="primary"
                    class="custom-button-save-faq"
                >
                  <CIcon name="cil-save"/>
                  Salva
                </CButton>

                <CButton
                    v-on:click="deleteStanzaFuneraria(stanza)"
                    type="submit"
                    size="md"
                    color="danger"
                    class="custom-button-destroy-faq"
                >
                  <CIcon name="cil-trash"/>
                  Rimuovi
                </CButton>

                <br>
                <br>
                <h5>Nome Stanza</h5>
                <CInput
                    v-model="stanza.nome_stanza"
                />
                <h5>Logo Stanza</h5>

                <UploadSinglePhotoAndPreview
                    @getPhoto="(photo)=> {stanza.foto_stanza= photo}"
                    :img="stanza.foto_stanza"
                    title="Carica qui il logo della stanza funeraria"
                    subtitle="Logo Stanza Funeraria"
                />

                <br>
                <h5>Descrizione Stanza</h5>
                <textarea v-model="stanza.descrizione" rows="20" cols="100"></textarea>
                <br>
                <br>
                <h5>Foto Stanza</h5>

                <UploadMultiplePhotoAndPreview
                    @getPhoto="(photo)=> {stanza.img_stanza= photo}"
                    :img="stanza.img_stanza"
                    title="Stanza"
                />
                <br>
                <br>

              </CCardHeader>
              <br>
            </div>

             <CButton
                v-on:click="createStanzaFuneraria(item)"
                type="submit"
                size="md"
                color="info"
            >
              <CIcon name="cil-plus"/>
              Aggiungi
            </CButton>

          </CCardBody>

        </CCard>
      </CCol>
    </CRow>


  </div>
</template>

<script>
import UploadSinglePhotoAndPreview from "@/views/funer24/components/UploadSinglePhotoAndPreview";
import UploadMultiplePhotoAndPreview from "@/views/funer24/components/UploadMultiplePhotoAndPreview";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Casafuneraria",
  components: {
    UploadSinglePhotoAndPreview,
    UploadMultiplePhotoAndPreview,
  },
  computed: {
    ...mapGetters('casafuneraria', {
      getItemById: 'getItemById',
    }),
    ...mapGetters('stanzafuneraria', {
      items: 'items',
    }),


  },
  data: function () {
    return {
      item: {},
      title: 'Logo'
    };
  },
  methods: {

    ...mapActions(
        "casafuneraria",
        {
          createCasaFuneraria: 'create',
          updateCasaFuneraria: 'update',
        }),

    ...mapActions(
        "stanzafuneraria",
        {
          listStanzaFuneraria: "list",
          createStanzaFuneraria: 'create',
          deleteStanzaFuneraria: 'delete',
          updateStanzaFuneraria: 'update'
        }),

  },
  created() {

    if (this.$route.params.id !== undefined) {
      this.listStanzaFuneraria(this.$route.params.id)
      this.item = this.getItemById(this.$route.params.id)
    }

  }

}
</script>

<style scoped>
.custom-button-destroy-faq.btn-danger {
  margin-left: 12px;
}
</style>
